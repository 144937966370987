import { useStaticQuery, graphql } from 'gatsby';

export const AllImagesFeed = () => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          featuredImage {
            node {
              sourceUrl
              mediaDetails {
                height
                width
                sizes {
                  sourceUrl
                  height
                  width
                  file
                }
              }
              localFile {
                childImageSharp {
                  id
                  gatsbyImageData(
                    placeholder: BLURRED
                    quality: 90
                    blurredOptions: {}
                  )
                }
              }
            }
          }
          id
        }
      }
    }
  `);
  return data;
};



  // const data = useStaticQuery(graphql`
  //   {
  //     allWpPost(sort: { fields: date, order: DESC }) {
  //       nodes {
  //         featuredImage {
  //           node {
  //             sourceUrl
  //             mediaDetails {
  //               height
  //               width
  //               sizes {
  //                 sourceUrl
  //                 height
  //                 width
  //                 file
  //               }
  //             }
  //           }
  //         }
  //         title
  //         id
  //       }
  //     }
  //   }
  // `);