import React, { useEffect } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const initCalcColumnRow = () => {
	const isBrowser = typeof window !== 'undefined';
	if (!isBrowser) return 3;

	const rows = Math.floor(window.innerWidth / 300);

	if (rows === 1) return 2;
	return rows;
};

let columnRow = initCalcColumnRow();

let totalOffset = 10;
let offset = totalOffset / 2;

let imgWidthPos = offset;
let imgHeightPos = 0;

let imgGridView = {};
let imgGridAdded = {};

// let GLOB = [];

const imgPrinter = (img, order) => {
	const { id } = img;
	const { mediaDetails, sourceUrl } = img.featuredImage.node;
	const thumbnail = mediaDetails.sizes[0];
	let docBodyWidth;
	
	++order;

	const isBrowser = typeof document !== 'undefined';
	if (!isBrowser) {
		docBodyWidth = 1000;
	} else {
		docBodyWidth = document.body.clientWidth;
	}
	const width = (docBodyWidth - totalOffset) / columnRow;
	const aspect = calcAspectRatio(mediaDetails);
	const height = aspect * width;

	const wrapperStyle = {
		width: width,
		height: height + 30,
		top: imgHeightPos,
		left: imgWidthPos,
	};

	const meta = Object.assign(wrapperStyle, {
		aspect: aspect,
		mediaDetails: mediaDetails,
		original: sourceUrl,
	});

	// const imageStyle = {
	// 	backgroundImage: 'url("' + thumbnail.sourceUrl + '")',
	// };

	// const imageProps = {
	// 	style: imageStyle,
	// 	className: 'the-image',
	// 	key: order,
	// };

	const wrapperProps = {
		style: wrapperStyle,
		className: 'box-wrapper-ai',
		onClick: openImage,
		meta: JSON.stringify(meta),
		key: order,
		role: 'article',
		tabIndex: 0,
	};

	// Replace meta ??????
	// GLOB.push(meta);

	imgGridView[order] = wrapperStyle;
	imgGridAdded[order] = wrapperStyle;

	let addedMin = {
		min: Infinity,
		key: 0,
	};

	const minView = Object.keys(imgGridView).reduce((acc, val) => {
		if (order < columnRow) {
			acc.min = 0;
			acc.key = 0;
		}

		const focus = imgGridView[val];
		const result = focus.top + focus.height;

		if (acc.min === undefined || result < acc.min) {
			acc.min = result;
			acc.key = val;
		}

		return acc;
	}, {});

	imgHeightPos = minView.min;

	Object.keys(imgGridAdded).forEach(each => {
		if (order < columnRow) {
			imgWidthPos = order * width + offset;
			return;
		}

		// imgWidthPos = imgGridAdded[each].left + width; // 0/350/700/1050
		const isBrowser = typeof window !== 'undefined';
		let browserWidth;
		if (!isBrowser) {
			browserWidth = 1000;
		} else {
			browserWidth = window.innerWidth;
		}

		if (imgWidthPos >= browserWidth) {
			imgWidthPos = 0;
		}

		for (let index in imgGridAdded) {
			const result = imgGridAdded[index].top + imgGridAdded[index].height;

			if (result < addedMin.min) {
				addedMin.min = result;
				addedMin.key = index;
			}
		}

		imgWidthPos = imgGridAdded[addedMin.key].left;
	});

	delete imgGridView[minView.key];
	delete imgGridAdded[addedMin.key];

	const theImageSpan = React.createElement('span', {className: 'the-bottom-edges', key:'bottom-edge'}, ' ');

	// const theImage = React.createElement('div', imageProps, theImageSpan);

	const imgPath = getImage(img.featuredImage.node.localFile);
	const theGImage = (
		<GatsbyImage image={imgPath} className="the-image" key={id} alt="All images" />
	);

	const divWrapper = React.createElement('div', wrapperProps, [theGImage, theImageSpan]);

	return divWrapper;
};

// const imgRerender = (img, order) => {
// 	const oldMeta = JSON.parse(img.getAttribute('meta'));

// 	++order;
// 	const width = (document.body.clientWidth - totalOffset) / columnRow;
// 	const height = oldMeta.aspect * width;

// 	const wrapperStyle = {
// 		width: width,
// 		height: height + 30,
// 		top: imgHeightPos,
// 		left: imgWidthPos,
// 	};

// 	const meta = Object.assign(wrapperStyle, {
// 		aspect: oldMeta.aspect,
// 		mediaDetails: oldMeta.mediaDetails,
// 	});

// 	imgGridView[order] = wrapperStyle;
// 	imgGridAdded[order] = wrapperStyle;

// 	let min = Infinity;
// 	let minKey = 0;

// 	let addedMin = {
// 		min: Infinity,
// 		key: 0,
// 	};

// 	Object.keys(imgGridView).forEach(each => {
// 		if (order < columnRow) return;

// 		for (let index in imgGridView) {
// 			const result = imgGridView[index].top + imgGridView[index].height;

// 			if (result < min) {
// 				min = result;
// 				minKey = index;
// 			}
// 		}

// 		imgHeightPos = min;
// 	});

// 	Object.keys(imgGridAdded).forEach(each => {
// 		if (order < columnRow) {
// 			imgWidthPos = order * width + offset;
// 			return;
// 		}

// 		imgWidthPos = imgGridAdded[each].left + width; // 0/350/700/1050
// 		if (imgWidthPos >= window.innerWidth) {
// 			imgWidthPos = 0;
// 		}

// 		for (let index in imgGridAdded) {
// 			const result = imgGridAdded[index].top + imgGridAdded[index].height;

// 			if (result < addedMin.min) {
// 				addedMin.min = result;
// 				addedMin.key = index;
// 			}
// 		}

// 		imgWidthPos = imgGridAdded[addedMin.key].left;
// 	});

// 	delete imgGridView[minKey];
// 	delete imgGridAdded[addedMin.key];

// 	img.setAttribute('meta', JSON.stringify(meta));

// 	img.style.width = meta.width + 'px';
// 	img.style.height = meta.height + 'px';
// 	img.style.left = meta.left + 'px';
// 	img.style.top = meta.top + 'px';
// };

const calcAspectRatio = img => {
	const ratio = img.height / img.width;
	return ratio;
};

const openImage = self => {
	let parent = self.target
		? self.target.closest('.box-wrapper-ai')
		: self.parentNode;

	const meta = JSON.parse(parent.getAttribute('meta'));
	if (!meta) return;
	const { width, height } = meta.mediaDetails;
	const cliW = document.body.clientWidth;
	const cliH = window.innerHeight;
	const overlay = document.querySelector('.grid-ai-overlay');
	const ratio = Math.min(cliW / width, cliH / height);
	const wrapper = document.querySelector('.grid-wrapper');

	window.see = self;

	// self.target.style.backgroundImage = "url('" + meta.original + "')";

	if (parent.classList.contains('BIGOS')) {
		resetOverlay(parent, meta);
		return;
	}

	overlay.classList.add('active');

	let newWidth = width * ratio;
	let newHeight = height * ratio;

	// Use image oringal size of screensize is too big
	if (newWidth > width && newHeight > height) {
		newWidth = width;
		newHeight = height;
	}

	const center = cliW / 2 - newWidth / 2;
	const wrapperOffset = wrapper.offsetTop;
	const heightLeftOver = (cliH - newHeight) / 2;
	const top = window.scrollY - wrapperOffset + heightLeftOver;

	parent.style.width = newWidth + 'px';
	parent.style.height = newHeight + 'px';
	parent.style.left = center + 'px';
	parent.style.top = top + 'px';
	parent.classList.add('BIGOS');

	// wrapper.classList.add('img-is-highlighted');
	// document.querySelector('header').classList.add('img-is-highlighted');

	// showNextAndPrevImg();
};

const resetOverlay = (imgBox, meta) => {
	const overlay = document.querySelector('.grid-ai-overlay');
	const wrapper = document.querySelector('.grid-wrapper');

	resetImage(imgBox, meta);

	overlay.classList.remove('active');

	wrapper.classList.remove('img-is-highlighted');
	document.querySelector('header').classList.remove('img-is-highlighted');
};

const resetImage = (imgBox, meta) => {
	imgBox.style.width = meta.width + 'px';
	imgBox.style.height = meta.height + 'px';
	imgBox.style.left = meta.left + 'px';
	imgBox.style.top = meta.top + 'px';

	imgBox.classList.add('animating');
	const transitionEnd = () => {
		imgBox.classList.remove('BIGOS', 'animating');
		imgBox.removeEventListener('transitionend', transitionEnd);
	};
	imgBox.addEventListener('transitionend', transitionEnd, false);

	resetPrevNext();
};

const nextPrevResetImage = (imgBox, meta) => {
	imgBox.style.width = meta.width + 'px';
	imgBox.style.height = meta.height + 'px';
	imgBox.style.left = meta.left + 'px';
	imgBox.style.top = meta.top + 'px';

	imgBox.classList.remove('BIGOS');
	resetPrevNext();
};

const resetPrevNext = () => {
	const prev = document.querySelector('.PREV');
	if (prev) {
		const prevMeta = JSON.parse(prev.getAttribute('meta'));
		prev.style.left = prevMeta.left + 'px';
		prev.style.top = prevMeta.top + 'px';
		prev.classList.remove('PREV');
	}

	const next = document.querySelector('.NEXT');
	if (next) {
		const nextMeta = JSON.parse(next.getAttribute('meta'));
		next.style.left = nextMeta.left + 'px';
		next.style.top = nextMeta.top + 'px';
		next.classList.remove('NEXT');
	}
};

const clickOverlay = self => {
	const activeImage = document.querySelector('.BIGOS');
	const meta = JSON.parse(activeImage.getAttribute('meta'));

	resetOverlay(activeImage, meta);
};

const goToNextImg = () => {
	const activeImage = document.querySelector('.BIGOS');
	if (!activeImage) return;
	const meta = JSON.parse(activeImage.getAttribute('meta'));

	if (
		!activeImage.nextSibling ||
		!activeImage.nextSibling.classList.contains('box-wrapper-ai')
	)
		return;
	nextPrevResetImage(activeImage, meta);
	openImage(activeImage.nextSibling.querySelector('.the-image'));
};

const goToPrevImg = () => {
	const activeImage = document.querySelector('.BIGOS');
	if (!activeImage) return;
	const meta = JSON.parse(activeImage.getAttribute('meta'));

	if (!activeImage.previousSibling.classList.contains('box-wrapper-ai')) return;
	nextPrevResetImage(activeImage, meta);
	openImage(activeImage.previousSibling.querySelector('.the-image'));
};

// const showNextAndPrevImg = () => {
// 	const activeImage = document.querySelector('.BIGOS');

// 	document.querySelectorAll('.box-wrapper-ai').forEach(function (each) {
// 		each.classList.remove('NEXT', 'PREV');
// 	});

// 	const wrapper = document.querySelector('.grid-wrapper');
// 	const wrapperOffset = wrapper.offsetTop;
// 	const cliW = document.body.clientWidth;
// 	const cliH = window.innerHeight;

// 	const next = activeImage.nextSibling;
// 	if (next && next.classList.contains('box-wrapper-ai')) {
// 		const nextMeta = JSON.parse(next.getAttribute('meta'));

// 		const nextLeft = cliW - nextMeta.width + 100;
// 		const nextHeightLeftOver = (cliH - nextMeta.height) / 2;
// 		const nextTop = window.scrollY - wrapperOffset + nextHeightLeftOver;

// 		next.style.left = nextLeft + 'px';
// 		next.style.top = nextTop + 'px';
// 		next.classList.add('NEXT');
// 	}

// 	const prev = activeImage.previousSibling;
// 	if (prev.classList.contains('box-wrapper-ai')) {
// 		const prevMeta = JSON.parse(prev.getAttribute('meta'));

// 		// const prevLeft = cliW - prevMeta.width;
// 		const prevHeightLeftOver = (cliH - prevMeta.height) / 2;
// 		const prevTop = window.scrollY - wrapperOffset + prevHeightLeftOver;

// 		prev.style.left = -100 + 'px';
// 		prev.style.top = prevTop + 'px';
// 		prev.classList.add('PREV');
// 	}
// };

const getLastImgs = () => {
	var list = [].slice.call(document.querySelectorAll('.box-wrapper-ai'));

	return list.sort((a, b) => {
		const c = parseFloat(a.style.top) + parseFloat(a.style.height);
		const d = parseFloat(b.style.top) + parseFloat(b.style.height);
		return c - d;
	});
};

const renderEndPlaceholder = () => {
	const imgs = getLastImgs();
	const list = imgs.splice(imgs.length - columnRow, imgs.length);

	const lastImg = list[list.length - 1];
	const lastImgTop = parseFloat(lastImg.style.top);
	const lastImgHeight = parseFloat(lastImg.style.height);
	const lastImgTotal = lastImgTop + lastImgHeight;

	list.forEach(function (each, index) {
		if (index === list.length - 1) return;
		const imgTop = parseFloat(each.style.top);
		const imgHeight = parseFloat(each.style.height);
		const imgTotal = imgTop + imgHeight;
		const newHeight = lastImgTotal - imgTotal;

		if (newHeight <= 100) return;

		const div = document.createElement('div');
		div.classList.add('box-placeholder-ai');
		div.style.top = imgTotal + 'px';
		div.style.left = each.style.left;
		div.style.height = newHeight + 'px';
		div.style.width = each.style.width;

		const content = document.createElement('div');
		const span = document.createElement('span');
		content.appendChild(span);
		div.appendChild(content);
		document.querySelector('.grid-wrapper').appendChild(div);
	});
};

// const resetPlaceholders = () => {
// 	const placeholders = document.querySelectorAll(
// 		'.grid-wrapper .box-placeholder-ai'
// 	);
// 	if (!placeholders) return;
// 	placeholders.forEach(elem => elem.remove());
// 	renderEndPlaceholder();
// };

// let resizeTimeout;

// const handleResize = () => {
// 	clearTimeout(resizeTimeout);
// 	resizeTimeout = setTimeout(resizeMe, 300);
// };

// window.addEventListener('resize', handleResize);

// const resizeMe = () => {
// 	// Listen if width has changed only?

// 	console.log('resizeMe', columnRow);
// 	columnRow = initCalcColumnRow();

// 	let allImg = document.querySelectorAll('.grid-wrapper .box-wrapper-ai');
// 	// console.log('GLOB', GLOB); // too be determen
// 	allImg.forEach(function (each, index) {
// 		// console.log('each', each)
// 		imgRerender(each, index);
// 	});

// 	resetPlaceholders();
// 	applyHeightToWrapper();
// };

const applyHeightToWrapper = () => {
	const allImg = document.querySelectorAll(
		'.grid-wrapper .box-wrapper-ai, .grid-wrapper .box-placeholder-ai'
	);

	const list = [].slice.call(allImg);

	const tallest = list.reduce((acc, item) => {
		const tallness = parseFloat(item.style.top) + parseFloat(item.style.height);
		return acc > tallness ? acc : tallness;
	}, 0);

	document.querySelector('.grid-wrapper').style.height = tallest + 100 + 'px';
};

const resetDataForNextPage = () => {
	imgWidthPos = offset;
	imgHeightPos = 0;
	imgGridView = {};
	imgGridAdded = {};
};

const listenToKeystroke = e => {
	if (
		e.keyCode === 27 ||
		e.key === 'Escape' ||
		e.key === 'q' ||
		e.key === 'x' ||
		e.key === 'Backspace'
	) {
		const activeImage = document.querySelector('.BIGOS');
		if (activeImage) {
			const meta = JSON.parse(activeImage.getAttribute('meta'));
			resetOverlay(activeImage, meta);
		}
	}
	if (e.keyCode === 39 || e.key === 'ArrowRight') {
		goToNextImg();
	}
	if (e.keyCode === 37 || e.key === 'ArrowLeft') {
		goToPrevImg();
	}
};

const GridAllImages = props => {
	const { nodes } = props.dataStream;
	// console.log('props', props)
	resetDataForNextPage();

	// console.log('nodes', nodes.length);

	// Run after everything is done?..
	useEffect(() => {
		renderEndPlaceholder();
		applyHeightToWrapper();
		resetDataForNextPage();

		document.querySelector('.grid-wrapper').classList.add('content-loaded');

		document.addEventListener('keydown', listenToKeystroke);
		return () => document.removeEventListener('keydown', listenToKeystroke);
	}, []);

	return (
		<div className="grid-wrapper">
		<div className="grid-ai-overlay" onClick={clickOverlay} onKeyDown={clickOverlay} role="button" aria-label="Close grid" tabIndex="-1"></div>
			{Object.keys(nodes).map(index => {
				return imgPrinter(nodes[index], index);
			})}
			<div className="grid-ai-total">Total of {nodes.length} images</div>
		</div>
	);
};

export default GridAllImages;
