import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import GridAllImages from '../code/grid-all-images';

import { AllImagesFeed } from '../importer/all-images-feed';
import Seo from '../components/seo';

const AllImages = () => {
	const data = AllImagesFeed();

	const loadingGrid = React.createElement(
		'div',
		{ className: 'the-loader', key: 1 },
		'Loading...'
	);
	const [grid, setGrid] = useState(loadingGrid);

	useEffect(() => {
		setGrid(<GridAllImages dataStream={data.allWpPost} />);
	}, [data.allWpPost]);

	return (
		<div>
			<Seo title2="All images" />
			<Header siteTitle="MrJane" />
			{grid}
		</div>
	);
};

export default AllImages;
